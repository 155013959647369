<style>
.country-info-window.gm-style-iw-c {
  max-width: 200px !important;
}
.country-info-window .gm-style-iw-d {
  position: relative;
  top: -27px;
}
.country-info-window button.gm-ui-hover-effect {
  right: -10px;
  top: -11px;
}

.image_section {
  flex: 0 0 200px; /* Set a fixed width for the image */
  margin-right: 20px; /* Space between the image and title */
  float: left;
  border: 1px solid #ece8e8;
  border-radius: 20px;
  padding: 10px;
}

.image_section img {
  width: 100%; /* Ensure image fills its container */
  height: 200px; /* Fixed height for the image */
  object-fit: cover; /* Ensure image is cropped and scaled proportionally */
}

.image_title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image_title h5 {
  margin: 0;
}

.image_title .book-button {
  margin-top: 10px;
}

.image_title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  transition: box-shadow 0.3s ease;
}

.image_title h5 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
}

.image_title a {
  font-size: 16px;
  line-height: 30px;
}

.address,
.property-id,
.property-type {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 8px;
}

.address {
  font-weight: 500;
}

.property-id,
.property-type {
  font-style: italic;
}

.rating-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.rating {
  display: flex;
  align-items: center;
}

.book-now {
  display: flex;
  justify-content: center;
}

.book-button {
  background-color: #ff762b;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-button:hover {
  background-color: #ff5722;
}

.image_title:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* This is a single-line comment */
.property-card {
  display: flex;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  max-width: 600px;
  margin: 20px;
}

.property-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.image-section {
  flex: 0 0 200px;
  overflow: hidden;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-section img:hover {
  transform: scale(1.05);
}

.content-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-section h5 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin: 0 0 10px;
  text-align: center;
}

.content-section .address {
  font-size: 1rem;
  color: #555;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}

.property-details {
  font-size: 0.9rem;
  color: #777;
  font-style: italic;
  margin-bottom: 20px;
  text-align: center;
}

.rating-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.book-button {
  background-color: #ff762b;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(255, 118, 43, 0.3);
}

.book-button:hover {
  background-color: #ff5722;
}

.rating {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #333;
}

.property-card h5 a {
  text-decoration: none;
  color: inherit;
}

.property-card h5 a:hover {
  color: #ff762b;
}

/* This is a single-line comment end  */
</style>
<template>
  <div class="my-5 container">
    <div class="text-main">
      <h2 class="font-weight-bolder">{{ $t("header.map") }}</h2>
    </div>
    <div class="mt-5 map-container">
      <div ref="map" id="map" style="width: 100%; height: 600px"></div>
      <div class="search-field-container">
        <div class="position-relative">
          <input
            id="search-field"
            type="text"
            placeholder="Search location"
            class="form-control search-field"
          />
          <b-spinner
            v-if="fetching"
            label="Spinning"
            class="search-field-spinner"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global google */
import { initPlaces, removeInstance } from "../helpers/map";
import { mapState, mapActions } from "vuex";
export default {
  name: "ReservationSuccess",
  data() {
    return {
      map: null,
      markers: [],
      propertiesCountData: 0,
      totalPropertiesWindow: null,
      countryWindow: null,
      continentPropertiesWindow: [],
      countryPropertiesWindow: [],
      continentSummary: [],
    };
  },

  async mounted() {
    window.vueApp = this;
    this.initMap();
    initPlaces("search-field", ({ latitude, longitude }) => {
      this.fetchProperties(latitude, longitude, 100, 10);
    });
    const response = await this.countProperties();
    const { data } = response.data;
    this.propertiesCountData = data;

    this.continentSummary = this.propertiesCountData.continent.map(
      (continent) => ({
        name: continent.name,
        count: continent.noOfPropertiesWithDistributorType,
        latitude: continent.latitude,
        longitude: continent.longitude,
      })
    );
  },
  beforeDestroy() {
    removeInstance();
  },
  computed: {
    ...mapState("property", ["properties", "fetching"]),
  },
  methods: {
    ...mapActions("property", ["searchProperties", "countProperties"]),
    initMap() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLat = position.coords.latitude;
            const userLng = position.coords.longitude;
            if (typeof google !== "undefined") {
              this.setMapWithConfig(userLat, userLng, 1);
            } else {
              console.error("Google Maps API is not loaded.");
            }
          },
          () => {
            console.error("Geolocation service failed.");
            this.initFallbackMap();
          }
        );
      } else {
        console.error("Your browser doesn't support geolocation.");
        this.initFallbackMap();
      }
    },
    initFallbackMap() {
      if (typeof google !== "undefined") {
        this.setMapWithConfig(-34.397, 150.644, 12);
      } else {
        console.error("Google Maps API is not loaded.");
      }
    },
    setMapWithConfig(lat, lng, zoom) {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat, lng },
        scrollwheel: true,
        zoom,
        mapId: "c75a5c4a3741e065",
      });

      const self = this;

      let debounceTimer;
      let lastZoomLevel = this.map.getZoom();

      google.maps.event.addListener(this.map, "dragend", () => {
        const currentZoomLevel = this.map.getZoom();

        if (lastZoomLevel !== currentZoomLevel) {
          lastZoomLevel = currentZoomLevel;
          return; // Exit if zoom level has changed
        }

        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          const center = this.map.getCenter();
          const latitude = center.lat();
          const longitude = center.lng();
          console.log(lastZoomLevel);
          self.fetchProperties(latitude, longitude, 100, lastZoomLevel);
        }, 800); // Adjust debounce delay as needed
      });

      // Function to open Info Window with conditional check for propertiesCountData
      function openInfoWindow() {
        // Ensure propertiesCountData is defined before attempting to use it
        if (
          self.propertiesCountData &&
          self.propertiesCountData.world &&
          !self.totalPropertiesWindow
        ) {
          self.clearMarkers();
          // eslint-disable-next-line no-undef
          self.totalPropertiesWindow = new google.maps.InfoWindow({
            content: `
        <div style="font-weight: 500;">You can sleep in ${self.propertiesCountData.world} properties</div>
        <button style="
              border: none;
              margin-top: 10px;
              background: none;
              padding: 0;
              color: #0000de;
              text-decoration: underline;
            "
            onclick="window.vueApp.handleInfoWindowZoomLevelChange(2)"
          >
            View Continents
          </button>
      `,
          });

          self.totalPropertiesWindow.setPosition({ lat: 0, lng: 0 });
          self.totalPropertiesWindow.open(self.map);

          // eslint-disable-next-line no-undef
          google.maps.event.addListener(
            self.totalPropertiesWindow,
            "closeclick",
            function () {
              self.totalPropertiesWindow = null;
            }
          );
        }
      }

      // Check if propertiesCountData is loaded, and call openInfoWindow once ready
      function initInfoWindowOnLoad() {
        if (self.propertiesCountData) {
          openInfoWindow();
        } else {
          // Poll every 100ms to check if propertiesCountData has loaded
          setTimeout(initInfoWindowOnLoad, 100);
        }
      }

      // Call initInfoWindowOnLoad instead of directly calling openInfoWindow
      initInfoWindowOnLoad();

      // eslint-disable-next-line no-undef
      google.maps.event.addListener(self.map, "zoom_changed", function () {
        const zoomLevel = self.map.getZoom();

        if (zoomLevel <= 1 && !self.totalPropertiesWindow) {
          self.clearMarkers();

          // eslint-disable-next-line no-undef
          self.totalPropertiesWindow = new google.maps.InfoWindow({
            content: `
          <div style="font-weight: 500;">You can sleep in ${self.propertiesCountData.world} properties</div>
          <button style="
                border: none;
                margin-top: 10px;
                background: none;
                padding: 0;
                color: #0000de;
                text-decoration: underline;
              "
              onclick="window.vueApp.handleInfoWindowZoomLevelChange(2)"
            >
              View Continents
            </button>
        `,
          });

          self.totalPropertiesWindow.setPosition({ lat: 0, lng: 0 });

          self.totalPropertiesWindow.open(self.map);
          // eslint-disable-next-line no-undef
          google.maps.event.addListener(
            self.totalPropertiesWindow,
            "closeclick",
            function () {
              self.totalPropertiesWindow = null;
            }
          );
        }

        if (zoomLevel > 1 && self.totalPropertiesWindow) {
          self.totalPropertiesWindow.close();
          self.totalPropertiesWindow = null;
        }
        if (zoomLevel >= 2 && zoomLevel <= 3) {
          self.clearMarkers();
          if (!self.continentPropertiesWindow.length) {
            self.propertiesCountData.continent.forEach((continent) => {
              // eslint-disable-next-line no-undef
              const pin = new google.maps.marker.PinElement({
                background: "#ff762b",
                borderColor: "#ff762b",
                glyphColor: "white",
                scale: 1.2,
              });

              // eslint-disable-next-line no-undef
              const marker = new google.maps.marker.AdvancedMarkerElement({
                position: { lat: continent.latitude, lng: continent.longitude },
                map: self.map,
                title: continent.name,
                content: pin.element,
              });

              // eslint-disable-next-line no-undef
              self.countryWindow = new google.maps.InfoWindow({
                content: `
              <div style="font-weight: 500; margin-top: 10px;">We have ${continent.noOfPropertiesWithDistributorType.toLocaleString()} properties in ${
                  continent.name
                }</div>
              <button style="
                    border: none;
                    margin-top: 10px;
                    background: none;
                    padding: 0;
                    color: #0000de;
                    text-decoration: underline;
                  "
                  onclick="window.vueApp.handleInfoWindowZoomLevelChange(4, ${
                    continent.latitude
                  }, ${continent.longitude})"
                >
                  View Countries
                </button>
            `,
              });
              self.countryWindow.open(self.map, marker);
              google.maps.event.addListener(
                self.countryWindow,
                "domready",
                () => {
                  // Find all elements with the class 'gm-style-iw'
                  const infoWindowElements =
                    document.querySelectorAll(".gm-style-iw");

                  infoWindowElements.forEach((element) => {
                    // Check if this is the "View Countries" info window by looking for unique text
                    if (element.innerHTML.includes("View Countries")) {
                      element.classList.add("country-info-window"); // Add the unique class
                    }
                  });
                }
              );
              marker.addListener("click", () =>
                self.countryWindow.open(self.map, marker)
              );
              self.continentPropertiesWindow.push(marker);
            });
          }
        } else if (self.continentPropertiesWindow.length) {
          self.continentPropertiesWindow.forEach((marker) => {
            marker.setMap(null);
          });
          self.continentPropertiesWindow = [];
        }

        let openInfoWindow = null; // Store the currently open InfoWindow

        if (zoomLevel >= 4 && zoomLevel <= 7) {
          if (!self.countryPropertiesWindow.length) {
            self.propertiesCountData.country.forEach((country) => {
              // eslint-disable-next-line no-undef
              const pin = new google.maps.marker.PinElement({
                background: "#ff762b",
                borderColor: "#ff762b",
                glyphColor: "white",
                scale: 1.2,
              });

              // eslint-disable-next-line no-undef
              const marker = new google.maps.marker.AdvancedMarkerElement({
                position: { lat: country.latitude, lng: country.longitude },
                map: self.map,
                title: country.name,
                content: pin.element,
              });

              // eslint-disable-next-line no-undef
              const infoWindow = new google.maps.InfoWindow({
                content: `
          <div class="g-logo" style="position: absolute;top: 15px;z-index: 99 ">
            <h6 class="design_logo" style="color: #EF762C;text-transform:uppercase;font-weight: 600;">
              Gloveler
            </h6>
          </div>
          <div style="font-weight: 500;">
            We have ${country.noOfPropertiesWithDistributorType.toLocaleString()} properties in ${
                  country.name
                }
          </div>
          <button style="
              border: none;
              margin-top: 10px;
              background: none;
              padding: 0;
              color: #0000de;
              text-decoration: underline;
            "
            onclick="window.vueApp.handleInfoWindowPropertiesSearch(${
              country.latitude
            }, ${country.longitude}, 200)"
          >
            View Properties
          </button>
        `,
              });

              marker.addListener("click", () => {
                // Close any open InfoWindow before opening a new one
                if (openInfoWindow) {
                  openInfoWindow.close();
                }
                infoWindow.open(self.map, marker);
                openInfoWindow = infoWindow; // Store the current InfoWindow
              });

              self.countryPropertiesWindow.push(marker);
            });
          }
        } else if (self.countryPropertiesWindow.length) {
          self.countryPropertiesWindow.forEach((marker) => {
            marker.setMap(null);
          });
          self.countryPropertiesWindow = [];
        }
      });
    },

    smoothZoom(targetZoom) {
      const currentZoom = this.map.getZoom();
      const step = targetZoom > currentZoom ? 0.2 : -0.2;

      const zoomAnimation = () => {
        const newZoom = this.map.getZoom() + step;

        if (
          (step > 0 && newZoom >= targetZoom) ||
          (step < 0 && newZoom <= targetZoom)
        ) {
          this.map.setZoom(targetZoom); // Final zoom level
        } else {
          this.map.setZoom(newZoom);
          requestAnimationFrame(zoomAnimation); // Continue animation
        }
      };

      requestAnimationFrame(zoomAnimation);
    },

    async handleInfoWindowZoomLevelChange(zoom, latitude = 0, longitude = 0) {
      // eslint-disable-next-line no-undef
      this.map.setCenter(new google.maps.LatLng(latitude, longitude));
      this.map.setZoom(zoom);
    },

    async handleInfoWindowPropertiesSearch(latitude, longitude, radius = 100) {
      await this.fetchProperties(latitude, longitude, radius, 4);
    },

    async fetchProperties(latitude, longitude, radius = 100, zoom = 8) {
      if (latitude && longitude) {
        await this.searchProperties({
          latitude,
          longitude,
          radius,
          page: 0,
          perPage: 1000,
        });

        this.clearMarkers();
        this.countryPropertiesWindow.forEach((marker) => {
          marker.setMap(null);
        });
        this.continentPropertiesWindow.forEach((marker) => {
          marker.setMap(null);
        });
        // eslint-disable-next-line no-undef
        this.map.setCenter(new google.maps.LatLng(latitude, longitude));
        this.smoothZoom(zoom);

        const geoCodeData = this.properties.content.map(
          ({ content: property }) => {
            if (property.media) {
              property.media
                .map((m) => {
                  m.order = m.sortOrderMain || Number.MAX_VALUE;
                  return m;
                })
                .sort((a, b) => a.order - b.order)
                .slice(0, 4);
            }

            let ratingValue = property.googleReviews?.googleRating || 0;
            let ratingCount =
              property.googleReviews?.googleNumberOfReviews || 0;
            ratingValue = parseFloat(
              ratingValue > 0 ? (ratingValue / 2).toFixed(1) : ratingValue
            );

            return {
              id: property.id,
              name: property.name,
              ...property.geoCode,
              distributorType: property.distributorType,
              ratingValue,
              ratingCount,
              address: property.addresses.length ? property.addresses[0] : null,
              image: property.media.length
                ? property.media[0].url
                : "/images/property-default-image.jpg",
            };
          }
        );
        this.displayMarkers(geoCodeData);
      }
    },

    async displayMarkers(geoCodeData) {
      let bookopenInfoWindow = null;
      geoCodeData.forEach((item) => {
        // eslint-disable-next-line no-undef
        const pin = new google.maps.marker.PinElement({
          background: "#ff762b",
          borderColor: "#ff762b",
          glyphColor: "white",
          scale: 1.2,
        });

        // eslint-disable-next-line no-undef
        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: { lat: item.latitude, lng: item.longitude },
          map: this.map,
          title: item.name + "--" + item.type,
          content: pin.element,
        });

        let propertyType;
        if (item.distributorType === "A") {
          propertyType = "Instant booking, real-time";
        } else if (item.distributorType === "B") {
          propertyType = "Instant booking";
        } else {
          propertyType = "Booking on request";
        }

        let ratingHtml = "";
        for (let i = 1; i <= 5; i++) {
          ratingHtml += `<i class="fa fa-star ${
            i <= item.ratingValue ? "text-warning" : "text-muted"
          }"></i>`;
        }

        if (item.ratingCount) {
          ratingHtml += `<span style="margin-left: 5px;">(${item.ratingCount})</span>`;
        }

        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode(
          { location: { lat: item.latitude, lng: item.longitude } },
          (results, status) => {
            let address = item.address;
            if (status === "OK" && results[0]) {
              address = results[0].formatted_address;
            }
            // eslint-disable-next-line no-undef
            const infoWindow = new google.maps.InfoWindow({
              content: `
          <div class="image_section">
          <a target="_blank" href="/listing/${item.id}">
          <img src="${item.image}" style="width: 200px; margin-bottom: 5px;" />
          </a>
          </div>
          <div class="image_title">
          <h5><a target="_blank" href="/listing/${item.id}">${item.name}</a></h5>
          <div style="padding-bottom: 5px; margin-top: 10px;">${address}</div>
          <div style="padding-bottom: 5px;">Property ID: ${item.id}</div>
          <div style="padding-bottom: 10px;">Property Type: ${propertyType}</div>
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div>${ratingHtml}</div>
            <div>
              <a target="_blank" href="/listing/${item.id}">
                <button style="padding: 5px 20px; color: white; background: #ff762b; border: none; border-radius: 20px; font-size: 14px;">Book Now</button>
              </a>
            </div>
            </div>
          </div>
        `,
            });

            marker.addListener("click", () => {
              if (bookopenInfoWindow) {
                bookopenInfoWindow.close();
              }
              infoWindow.open(this.map, marker);
              bookopenInfoWindow = infoWindow; // Store the current InfoWindow
            });

            this.markers.push(marker);
          }
        );
      });
    },
    clearMarkers() {
      this.markers.forEach((marker) => marker.setMap(null));
      this.markers = [];
    },
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
}
.search-field-container {
  position: absolute;
  top: 9px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.search-field {
  width: 300px;
}
.search-field-spinner {
  position: absolute;
  right: 5px;
  top: 8px;
  width: 25px;
  height: 25px;
  color: #ff762b;
}
</style>
