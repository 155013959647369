<template>
  <PropertyListingMap></PropertyListingMap>
</template>
<script>
import PropertyListingMap from "../components/PropertyListingMap.vue";
export default {
  name: "HotelListingMap",
  components: {
    PropertyListingMap,
  },
};
</script>
